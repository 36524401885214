import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const SocialLinks = loadable(() => import('/src/components/Basic/SocialLinks'))

const MobileLinks = ({ post }) => {
    const [options] = useContext(OptionsContext)
    const componentData = post.acfMobileLinks.linksList
    const itemsLength = componentData.length

    return (
        <div className="c-mobile-links">
            <div className="container-lg">
                <div className="c-mobile-links__inner">
                    <Link className={'c-mobile-links__logo'} to={'/'}>
                        <StaticImage
                            src={'../../../images/pl-logo.png'}
                            width={260}
                            alt={`${options.siteData.title} logo`}
                            className={'c-mobile-links__logo-image'}
                        />
                    </Link>

                    {componentData && (
                        <>
                            <div className="c-mobile-links__link-container">
                                {componentData.map((node, index) => {
                                    if (itemsLength === index + 1) {
                                        // Make the last item a gradient button
                                        return (
                                            <ButtonLink
                                                key={index}
                                                data={node.link}
                                                variant={'feature'}
                                                className={'c-mobile-links__link'}
                                            />
                                        )
                                    } else {
                                        return (
                                            <ButtonLink
                                                key={index}
                                                data={node.link}
                                                className={'c-mobile-links__link'}
                                            />
                                        )
                                    }
                                })}
                            </div>

                            <SocialLinks className={'c-mobile-links__social'} />

                            {options.globalDisclaimer && <ContentDisclaimer content={options.globalDisclaimer} />}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export const query = graphql`
    fragment MobileLinks on WpPage {
        acfMobileLinks {
            linksList {
                link {
                    target
                    title
                    url
                }
            }
        }
    }
`

MobileLinks.propTypes = {
    /**
     * Post data
     */
    post: PropTypes.shape({
        acfMobileLinks: PropTypes.shape({
            linksList: PropTypes.arrayOf(
                PropTypes.shape({
                    link: PropTypes.shape({
                        url: PropTypes.string.isRequired,
                        title: PropTypes.string.isRequired,
                        target: PropTypes.string
                    }).isRequired
                })
            )
        })
    })
}

MobileLinks.defaultProps = {
    post: {
        acfMobileLinks: {
            linksList: [
                {
                    link: {
                        url: '#',
                        title: 'Link 1',
                        target: ''
                    }
                },
                {
                    link: {
                        url: '#',
                        title: 'Link 2',
                        target: ''
                    }
                },
                {
                    link: {
                        url: '#',
                        title: 'Link 3',
                        target: ''
                    }
                },
                {
                    link: {
                        url: '#',
                        title: 'Link 4',
                        target: ''
                    }
                },
                {
                    link: {
                        url: '#',
                        title: 'The bottom link always has "feature" styling',
                        target: ''
                    }
                }
            ]
        }
    }
}

export default MobileLinks
